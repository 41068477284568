body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f0f0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

header {
    position: fixed;
    z-index: 100;
    top: 0;
    height: calc(50px - 8px);
    background-color: #edf4f0;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .23);
    padding: 4px 1rem 4px 1rem;
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

p {
    margin: 0;
}

ul {
    margin: 0;
}

li {
    list-style: none;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
}

input {
    margin: 0;
    padding: 0;
}

input:focus,
textarea:focus {
    outline: none;
}

a {
    color: #00836b;
    text-decoration: none;
}

textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

textarea:focus {
    background-color: #fcfade;
    border-color: #00836b;
    box-shadow: 0 0 3px #00836b;
}