.Center {
    position: relative;
    top: 51px;
    margin-left: 200px;
    padding: 3rem 2rem 3rem 2rem;
    height: calc(100% - 6rem - 51px);
}

.AuthCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SearchBox {
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    height: 47px;
    width: calc(30% - 9.6px);
    min-width: 350px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.SearchBox svg {
    font-size: calc(47px * 0.8);
    color: #bdbdbd;
    padding: 0 0.4rem;
    position: relative;
    top: 1px;
}

.SearchBoxInput {
    border: none;
    height: calc(100% - 16px - 4px);
    width: calc(100% - 50.38px);
    padding: 8px 16px 8px 0;
    font-size: 16px;
}

.BacklogOAuthDialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #c2c2c2;
    width: 40%;
    height: 35%;
    min-width: 430px;
    min-height: 250px;
}

.BacklogOAuthButtonField {
    display: flex;
    justify-content: center;
}

.BacklogOAuthButtonField>button>p {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 16px;
}

.BacklogOAuthDialog>div>button {
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #bbb;
    box-shadow: lightgray 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BacklogOAuthDialog>div>button>img {
    width: 45px;
}

.Spacefield {
    display: flex;
    flex-direction: column;
}

.Spacefield input {
    border: 1px solid #bbb;
    border-radius: 5px;
    font-size: 16px;
    height: 29px;
    margin-bottom: 15px;
    padding: 8px 16px;
    /* max-width: 300px; */
}