.ModalLoginOverlay {
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.ModalLoginDialog {
    background-color: #fff;
    border-radius: 4px;
    cursor: default;
    display: flex;
    flex-direction: column;
    height: 60%;
    overflow-y: scroll;
    padding: 1rem;
    position: relative;
    width: 25%;
    z-index: 1000;
}

@media screen and (max-width: 1500px) {
    .ModalLoginDialog {
        width: 30%;
    }
}

@media screen and (max-width: 1160px) {
    .ModalLoginDialog {
        width: 40%;
    }
}

@media screen and (max-width: 900px) {
    .ModalLoginDialog {
        width: 50%;
    }
}

@media screen and (max-width: 700px) {
    .ModalLoginDialog {
        width: 60%;
    }
}

@media screen and (max-width: 500px) {
    .ModalLoginDialog {
        width: 70%;
    }
}

@media screen and (max-width: 400px) {
    .ModalLoginDialog {
        width: 80%;
    }
}

@media screen and (max-height: 800px) and (min-width: 800px) {
    .ModalLoginDialog {
        height: 75%;
    }
}

@media screen and (max-height: 700px) and (min-width: 700px) {
    .ModalLoginDialog {
        height: 80%;
    }
}

.ModalLoginContent input {
    border: 1px solid #bbb;
    border-radius: 5px;
    font-size: 16px;
    height: 40px;
    padding: 8px 16px;
    width: calc(100% - 32px);
    margin-top: 1.5rem;
}

.ModalLoginDialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SubmitButtonWrapper {
    padding: 1rem 2rem;
}

.SubmitButton {
    width: 100%;
    background-color: #4caf93;
    color: #f0f0f0;
    font-weight: 600;
    font-size: 16px;
    padding: 0.7rem 0;
    border-radius: 10px;
    margin-top: 1.5rem;
}

.TailSpinContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}