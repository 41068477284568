.tasksContent {
    height: calc(100% - 47px);
}

.tasksContent ul {
    padding: 0;
    margin: 0;
    border-radius: 0 0 4px 4px;
    border: 1px solid #c2c2c2;
    border-top: 0;
    overflow-y: scroll;
}

.taskItem,
.tasksContent li {
    justify-content: space-between;
    padding: 10px;
}

.taskItem {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #bdbdbd;
    color: #00836b;
    padding-left: 20px;
    border: 1px solid #c2c2c2;
}

.tasksContent li {
    display: flex;
    align-items: center;
    border-radius: 0 0 4px 4px;
    background-color: #ffffff;
    border-top: 1px solid #c2c2c2;
}

.taskId {
    color: #00836b;
}

.taskId,
.taskKey,
.taskPriority,
.taskDueDate {
    flex: 2;
    text-align: center;
    cursor: pointer;
}

.taskName {
    flex: 4;
    text-align: center;
    cursor: pointer;
}